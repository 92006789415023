import * as React from "react"
import useInViewPort from "../../hooks/useInViewPort"

const YouTube = ({ src, title, className }) => {
  const [nodeRef, isVisible] = useInViewPort()
  return (
    <div ref={nodeRef} className={className}>
      {isVisible ? (
        <iframe
          title={title}
          src={src}
          width="100%"
          height="100%"
          className={" "}
          allowFullScreen="true"
          loading="lazy"
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default YouTube
