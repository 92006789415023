import * as React from "react"
import FAQCard from "./FAQs-data"

const FAQs = ({ className }) => {
  return (
    <section itemScope itemType="https://schema.org/FAQPage" className={" " + className}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 gap-x-10 w-10/12 max-w-[1366px] mx-auto">
        <h2 className="lg:col-span-2 font-bold text-5xl mx-auto">FAQ</h2>
        <p className="lg:col-span-2 text-neutral-500 text-2xl mx-auto">
          Frequently Asked Questions
        </p>
        <FAQCard />
      </div>
    </section>
  )
}

export default FAQs
